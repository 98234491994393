// MultipleDropdownWithFetchApi.js
import React, { useState, useEffect } from "react";
import axios from "../axios";

function MultipleDropdownWithFetchApi({ value , onChange, apiUrl }) {
  // Provide a default value for value
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("@token"),
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Fetch music fields from the API
    axios.get(apiUrl, { headers }).then((response) => {
      setOptions(response.data);
    });
  }, [apiUrl]);

  const handleSelectChange = (e) => {
    const selectedValues = Array.from(e.target.selectedOptions, (option) =>
      parseInt(option.value)
    );
    onChange(selectedValues);
  };

  return (
    <select
      multiple
      value={value}
      onChange={handleSelectChange}
      style={{ height: "150px" }}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
}

export default MultipleDropdownWithFetchApi;
