import axios from "axios";
import myAxios from "./axios";
import DropdownWithExternalFetchApi from "./components/DropDownWithExternalFetchApi";
const headers = {
  "accept": "application/json",
  "X-CSCAPI-KEY": "aUZxMTlnOUZLSHZ4aVhuZHNDRnM4WjZ2RFBpbkJYeklyZG5MYlBDSQ==",
};
const myHeaders={
    Authorization: "Bearer " + localStorage.getItem("@token"),
};

const layout = [
  {
    resource: "Users",
    icon: "bi-people",
    important_columns: ["id", "first_name", "last_name", "email", "role"],
    viewable_columns: [
      "id",
      "first_name",
      "last_name",
      "email",
      "role",
      "ph_number",
      "firebase_id",
      "uuid",
      "description",
      "address",
      "city",
      "state",
      "pincode",
      "country",
      "age",
      "music_field_id",
      "membership_id",
      "bachelor_qualification",
      "master_qualification",
      "doctorate_qualification",
      "created_at",
    ],
    createable_columns: [
      "first_name",
      "last_name",
      "email",
      "role",
      "ph_number",
      "firebase_id",
      "uuid",
      "description",
      "address",
      "pincode",
      "country",
      "state",
      "city",
      "age",
      "music_field_id",
      "membership_id",
      "bachelor_qualification",
      "master_qualification",
      "doctorate_qualification",
    ],
    editable_columns: [
      "first_name",
      "last_name",
      "email",
      "role",
      "ph_number",
      "description",
      "address",
      "pincode",
      "country",
      "state",
      "city",
      "age",
      "music_field_id",
      "membership_id",
      "bachelor_qualification",
      "master_qualification",
      "doctorate_qualification",
    ],
    column_type: {
      first_name: {
        type: "text",
        default: "",
        value: null,
      },
      last_name: {
        type: "text",
        default: "",
        value: null,
      },
      email: {
        type: "email",
        default: "",
        value: null,
      },
      role: {
        type: "dropdown",
        default: "",
        value: ["Student", "Guru", "Admin"],
      },
      ph_number: {
        type: "text",
        default: "",
        value: null,
      },
      firebase_id: {
        type: "text",
        default: "",
        value: null,
      },
      uuid: {
        type: "text",
        default: "",
        value: null,
      },
      description: {
        type: "text",
        default: "",
        value: null,
      },
      address: {
        type: "text",
        default: "",
        value: null,
      },
      pincode: {
        type: "number",
        default: null,
        value: null,
      }, 
      country: {
        type: "dropdownWithExternalFetchApi",
        default: "",
        value: null,
        apiURL:"https://api.countrystatecity.in/v1/countries/",
        customImplementation: async function (value) {
          Window.ciso = value;
          
          try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${Window.ciso}/states/`, { headers });
            console.log("response ",response);
             const data = await response.data;
             console.log("data ",data);
             // Assuming data is an array of state names
             const stateDropdown = document.getElementsByName("state")[0];
             stateDropdown.innerHTML = ""; // Clear existing options
             data.forEach(state => {
                const option = document.createElement("option");
                // console.log("state ",state.iso2);
                option.value = state.name;
                option.textContent = state.name;
                option.setAttribute("data-iso2", state.iso2);
                stateDropdown.appendChild(option);

             });    
             stateDropdown.onchange = function () {
              // Access the iso2 of the selected state
              const selectedOption = stateDropdown.options[stateDropdown.selectedIndex];
              const iso2 = selectedOption.getAttribute("data-iso2"); // Corrected attribute name
              console.log("Selected State ISO2:", iso2);
          
              // If you want to save the iso2 in a variable, update the variable here
              Window.siso = iso2;
              console.log("Window.siso:", Window.siso);
          };

          } catch (error) {
             console.error("Error fetching states:", error);
          }
       }
       
       
      },
      state: {
        type: "dropdownWithExternalFetchApi",
        default: "",
        value: null,
        apiURL: `https://api.countrystatecity.in/v1/countries/IN/states`,
        customImplementation: async function (value) {
          Window.siso = value;
          console.log("Window.siso ",Window.siso);
          try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${Window.ciso}/states/${Window.siso}/cities/`, { headers });
            console.log("response ",response);
             const data = await response.data;
             console.log("data ",data);
             // Assuming data is an array of state names
             const cityDropdown = document.getElementsByName("city")[0];
             cityDropdown.innerHTML = ""; // Clear existing options
             data.forEach(state => {
                const option = document.createElement("option");
                option.value = state.name;
                option.textContent = state.name;
                cityDropdown.appendChild(option);
             }); 
          } catch (error) {
             console.error("Error fetching states:", error);
          }
        }
      },
      city: {
        type: "dropdownWithExternalFetchApi",
        default: "",
        value: null,
        apiURL: `https://api.countrystatecity.in/v1/countries/IN/states/DL/cities`,
        customImplementation: function (value) {
        }
      },
      age: {
        type: "number",
        default: null,
        value: null,
      },
      music_field_id: {
        type: "dropdownWithFetchApi",
        default: null,
        value: null,
        apiURL: "musicfields",
      },
      membership_id: {
        type: "dropdownWithFetchApi",
        default: null,
        value: null,
        apiURL: "memberships",
      },
      bachelor_qualification: {
        type: "dropdownWithFetchApi",
        default: null,
        value: null,
        apiURL: "bachelorDegrees",
      },
      master_qualification: {
        type: "dropdownWithFetchApi",
        default: null,
        value: null,
        apiURL: "masterDegrees",
      },
      doctorate_qualification: {
        type: "dropdownWithFetchApi",
        default: null,
        value: null,
        apiURL: "doctorateDegrees",
      },
    },
  },

  {
    resource: "Classes",
    icon: "bi-book",
    important_columns: ["id", "title", "scheduled_at", "creator_id"],
    viewable_columns: [
      "id",
      "title",
      "description",
      "video_link",
      "scheduled_at",
      "creator_id",
      "created_at",
    ],
    createable_columns: [
      "title",
      "description",
      "video_link",
      "scheduled_at",
      "creator_id",
      "student_ids",
    ],
    editable_columns: ["title", "description","video_link", "scheduled_at", "creator_id"],
    column_type: {
      title: {
        type: "text",
        default: null,
        value: null,
      },
      description: {
        type: "text",
        default: null,
        value: null,
      },
      video_link: { 
        type: "text",
        default: null,
        value: null,
      },
      scheduled_at: {
        type: "datetime-local",
        step: 1,
        value: null,
      },
      creator_id: {
        type: "number",
        value: null,
      },
      student_ids: {
        type: "array",
        value: null,
      },
    },
  },

  {
    resource: "Notes",
    icon: "bi-music-note-list",
    important_columns: ["id", "title", "creator_id", "category_id"],
    viewable_columns: [
      "id",
      "title",
      "description",
      "creator_id",
      "category_id",
      "tag_ids",
      "audio_link",
      "image_link",
      "video_link",
      "created_at",
    ],
    createable_columns: [
      "title",
      "description",
      "creator_id",
      "category_id",
      "tag_ids",
      "audio_link",
      "image_link",
      "video_link"
    ],
    editable_columns: [
      "title",
      "description",
      "creator_id",
      "category_id",
      "tag_ids",
      "audio_link",
      "video_link",
      "image_link",
    ],
    column_type: {
      title: {
        type: "text",
        value: null,
      },
      description: {
        type: "text",
        value: null,
      },
      creator_id: {
        type: "number",
        value: null,
      },
      audio_link: {
        type: "text",
        value: null,
      },
      image_link: {
        type: "text",
        value: null,
      },
      video_link:{
        type: "text",
        value: null,
      },
      category_id: {
        type: "dropdownWithFetchApiAndCustomImplementation",
        value: null,
        apiURL: "leafCategories",
        customImplementation: async function (value) {
          try {
            const response = await myAxios.get(`/leafTags/${value}`, { myHeaders });
            console.log("response ",response);
             const data = await response.data.response;
             console.log("data ",data);
             // Assuming data is an array of state names
             const tagDropdown = document.getElementsByName("tag_ids")[0];
             tagDropdown.innerHTML = ""; // Clear existing options
             data.forEach(state => {
                const option = document.createElement("option");
                // console.log("state ",state.iso2);
                option.value = state.id;
                option.textContent = state.name;
                tagDropdown.appendChild(option);
             });    
             tagDropdown.multiple = true
          } catch (error) {
             console.error("Error fetching states:", error);
          }
        }
      },
      tag_ids: {
        type: "dropdownWithFetchApiAndCustomImplementationArray",
        apiURL: "tags",
        value: null,
        customImplementation: function (value) {
        }
      },
    },
  },
  {
    resource: "Category",
    icon: "bi-ui-checks-grid",
    important_columns: ["id", "name", "parent_id","left","right"],
    viewable_columns: ["id", "name", "image_link", "parent_id","left","right"],
    createable_columns: ["name", "image_link", "parent_id","left","right"],
    editable_columns: ["name", "image_link", "parent_id","left","right"],
    column_type: {
      id: {
        type: "number",
        default: null,
        value: null,
      },
      name: {
        type: "text",
        value: null,
      },
      parent_id: {
        type: "number",
        value: null,
      },
      image_link: {
        type: "text",
        value: null,
      },
      left: {
        type: "number",
        value: null,
      },
      right: {
        type: "number",
        value: null,
      },
    },
  },
  {
    resource: "Tags",
    icon: "bi-tags",
    important_columns: ["id", "name", "root_category_id"],
    viewable_columns: ["id", "name", "image_link", "root_category_id"],
    createable_columns: ["name", "image_link", "root_category_id"],
    editable_columns: ["name", "image_link", "root_category_id"],
    column_type: {
      id: {
        type: "number",
        value: null,
      },
      name: {
        type: "text",
        value: null,
      },
      root_category_id: {
        type: "number",
        value: null,
      },
      image_link: {
        type: "text",
        value: null,
      },
    },
  },

  {
    resource: "Payments",
    icon: "bi-currency-rupee",
    important_columns: ["id", "status", "amount", "created_at"],
    viewable_columns: ["id", "status", "amount", "created_at"],
    createable_columns: ["studentId", "amount", "classIds"],
    editable_columns: ["amount", "status"],
    column_type: {
      id:{
         type:"number",
         value:null,
      },
      studentId: {
        type: "number",
        value: null,
      },
      amount: {
        type: "number",
        value: null,
      },
      classIds: {
        type: "array",
        value: null,
      },
      status: {
        type: "dropdown",
        value: ["pending", "paid", "failed"],
      },
      created_at: {
        type: "datetime-local",
        step: 1,
        value: null,
      },
    },
  },
  {
    resource: "NoteReviews",
    icon: "bi-app-indicator",
    important_columns: ["id", "note_id", "status"],
    viewable_columns: [
      "id",
      "note_id",
      "reviewer_id",
      "rating",
      "comment",
      "status",
    ],
    createable_columns: ["rating", "comment", "note_id"],
    editable_columns: ["reviewer_id", "rating", "comment", "status"],
    column_type: {
      id: {
        type: "number",
        value: null,
      },
      note_id: {
        type: "number",
        value: null,
      },
      reviewer_id: {
        type: "number",
        value: null,
      },
      rating: {
        type: "number",
        value: null,
      },
      comment: {
        type: "text",
        value: null,
      },
      status: {
        type: "dropdown",
        default: "Pending",
        value: ["Pending", "Approved", "Rejected"],
      },
    },
  },
];

export default layout;
