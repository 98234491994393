import React, { useState, useEffect } from "react";
import axios from "../axios";

function DropdownWithFetchApi({ name, value, onChange, apiUrl }) {
  console.log("apiUrl main", apiUrl);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("@token"),
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Fetch music fields from the API
    axios.get(apiUrl, { headers }).then((response) => {
      setOptions(response.data);
    });
  }, [apiUrl]);

  return (
    <select name={name} value={value} onChange={onChange}>
      <option key={null} value={null}>
        Select Below
      </option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
}

export default DropdownWithFetchApi;
