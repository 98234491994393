import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5oYf62n7PGbmnqFhPI0lpmW7sw14uPsU",
  authDomain: "singtico-b129e.firebaseapp.com",
  databaseURL: "https://singtico-b129e.firebaseio.com",
  projectId: "singtico-b129e",
  storageBucket: "singtico-b129e.appspot.com",
  messagingSenderId: "437414842486",
  appId: "1:437414842486:web:2f3eb9855e0f74365a4f57",
  measurementId: "G-CNLBT9N86K",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { auth, firebase };