import React, { useState, useEffect } from "react";
import axios from "axios";

function DropdownWithExternalFetchApi({ name, value, onChange, apiUrl }) {
    console.log("apiUrl main",apiUrl)
  const headers = {
    "accept": "application/json",
    "X-CSCAPI-KEY": "aUZxMTlnOUZLSHZ4aVhuZHNDRnM4WjZ2RFBpbkJYeklyZG5MYlBDSQ==",
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log("useEffect ",apiUrl);
    axios.get(apiUrl, { headers }).then((response) => {
      console.log("response ",response);
      setOptions(response.data);
    });
  }, [apiUrl]);
 
  return (
    <select name={name} value={value} onChange={onChange}>
      <option key={null} value={null}>
        Select Below
      </option>
      {console.log("options ",options)}
      {options.map((option) => (
        <option key={option.id} value={option.name} data-iso2={option.iso2} selected={option === value}>
          {option.name}
        </option>
      ))}
      {/* {console.log("iso2 ",iso2)} */}
      {/* {customImplementation(data-iso2)} */}
    </select>
  );
}

export default DropdownWithExternalFetchApi;
